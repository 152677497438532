import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import image404 from '@images/404.png'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{textAlign: 'center'}}>
      <h1 style={{fontSize: '2.5rem', marginBottom: '92px', marginTop: '92px'}}>Page Not Found</h1>
      <img src={image404} alt='404-error'/>
    </div>
  </Layout>
)

export default NotFoundPage
